<template>
  <div class='notice_detail'>
    <Title style="height: 800px;" :chs="chs" :en="en" :flag="$route.path" />

    <div class="detail">
      <div class="back" @click="$router.go(-1)">
        <img src="../assets/img/back.png" width="26" height="24">
        <span>返回</span>
      </div>

      <div class="detail_body">
        <div class="detail_title">{{ obj.title }}</div>
        <div class="detail_time">发布日期：{{ obj.createdTime }}</div>
        <div class="detail_desc" v-html="obj.content"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      chs: '公告信息',
      en: 'Announcement information',
      obj: {},
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.$axios.get(this.$api.selectNoticeInfoDetail, {
        params: {
          id: this.$route.query.id
        }
      }).then((res) => {
        this.obj = res.result
      }).catch((err) => {
        this.$message.error(err.desc)
      })
    },
  },
}
</script>

<style scoped lang='scss'>
.notice_detail {
  height: 100%;
  background-color: #EEF4FF;

  .detail {
    margin: 0 80px;
    transform: translateY(-60px);
    box-sizing: border-box;

    .back {
      display: flex;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }

    .detail_body {
      padding: 60px;
      margin: 40px 0 0;
      border-radius: 10px;
      background-color: #fff;
      box-sizing: border-box;

      .detail_title {
        color: #171717;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 30px;
        text-align: center;
      }

      .detail_time {
        color: #666666;
        font-size: 16px;
        margin-bottom: 40px;
        text-align: center;
      }

      .detail_desc {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
